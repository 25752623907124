import { createSlice } from '@reduxjs/toolkit';
import { EStatusTable } from 'graphql/table/table';

export interface globalStateType {
    searchText: {
        order: string;
        table: string;
    };
    filterOrder: string;
    filterTable: EStatusTable;
    merchantFilterTable: string;
    orderId: string | null;
}

const initialState: globalStateType = {
    searchText: {
        order: '',
        table: '',
    },
    filterOrder: 'PICKUP,DINING',
    filterTable: EStatusTable.ALL,
    merchantFilterTable: 'SERVING,RESERVED,AVAILABLE',
    orderId: null,
};

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        updateSearch: (state, action) => {
            state.searchText = action.payload.searchText;
        },
        updateFilterOrder: (state, action) => {
            state.filterOrder = action.payload.filterOrder;
        },
        updateFilterTable: (state, action) => {
            state.filterTable = action.payload.filterTable;
        },
        updateMerchantFilterTable: (state, action) => {
            state.merchantFilterTable = action.payload.merchantFilterTable;
        },
        openOrderDetail: (state, action) => {
            state.orderId = action.payload.orderId;
        },
    },
});

export const {
    updateSearch,
    updateFilterOrder,
    updateFilterTable,
    updateMerchantFilterTable,
    openOrderDetail,
} = globalSlice.actions;

export default globalSlice.reducer;
